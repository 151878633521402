<template>
  <div class="container"></div>
</template>

<script>
export default {
  data () {
    return {
      title: 'about'
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
